body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-between {
  display: flex;
  justify-content: space-between;
}

p {
  margin: 0;
  padding: 0;
}
.ant-layout-header {
  height: 55px;
  line-height: 55px;
}
